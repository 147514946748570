<script setup>
import { usePlatform } from '@/composables/platform'
import { useStore } from '@/stores'
import { computed } from 'vue'

const platform = usePlatform()
const props = defineProps({
  image: { type: String, default: null },
})

const store = useStore()
const establishment = computed(() => store.getters['establishment/establishment'])
const image = computed(() => {
  if (props.image && props.image.length) {
    return props.image
  }
  return establishment.value?.cover ?? null
})
</script>

<template>
  <div class="visual__container">
    <div class="visual">
      <div class="visual__blue-swoosh" />
      <div :class="[ 'visual__image', { 'visual__image--ios': platform == 'ios', 'visual__image--android': platform == 'android' } ]"
           :style="{ ...(image !== null ? { backgroundImage: `url('${image}')`} : {}) }"
      />
      <div class="visual__darken" />
      <div class="visual__white-swoosh" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.visual {
  position: relative;

  // Ratio of the mask between width and height
  $mask-ratio: 0.3932229398;

  @include lg-up {
    position: absolute;
    top: 0;
    right: -45rem;

    $size: 80rem;
    width: $size;
    height: calc($size * $mask-ratio);
  }

  @include xl-up {
    right: -38rem;
  }

  @include xxl-up {
    right: -40rem;
    top: -4rem;

    $size: 90rem;
    width: $size;
    height: calc($size * $mask-ratio);
  }

  @include xxxl-up {
    right: -38rem;
  }

  &__container {
    @include lg-up {
      position: absolute;
      top: 0;
      left: 50%;
      // matches grid.scss -> .container
      width: 58rem;
      height: 100%;
      transform: translateX(-50%);
    }

    @include xl-up {
      width: 71rem;
    }

    @include xxl-up {
      width: 83.5rem;
    }

    @include xxxl-up {
      width: 90rem;
    }
  }

  &__image {
    width: 100%;
    height: 14rem;
    background-size: cover;
    background-position: center;

    &--ios {
      height: 16rem;
    }

    &--android {
      height: calc(env(safe-area-inset-top) + 14rem)
    }

    @include lg-up {
      height: 100%;
      background-size: 77%;
    }
  }

  &__darken {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(36, 46, 74, 0.1);
  }

  &__image,
  &__darken {
    mask: url('/assets/img/establishmentpage/header/mobile-swoosh-mask.svg');
    mask-position: bottom center;
    mask-repeat: no-repeat;
    mask-size: 100% auto;

    @include sm-up {
      mask-image: url('/assets/img/establishmentpage/header/tablet-swoosh-mask.svg');
    }

    @include lg-up {
      mask-image: url('/assets/img/establishmentpage/header/desktop-swoosh-mask.svg');
      mask-position: bottom left;
    }
  }

  &__white-swoosh {
    display: none;

    // The height, relative to the height of the image
    $swoosh-height-ratio: 0.953445065176909;
    $swoosh-width-ratio: 0.981265036276497;

    @include lg-up {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% * $swoosh-width-ratio);
      height: calc(100% * $swoosh-height-ratio);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-image: url('/assets/img/establishmentpage/header/desktop-swoosh-line.svg');
    }
  }

  &__blue-swoosh {
    display: none;

    @include lg-up {
      display: block;
      position: absolute;
      top: 0;
      left: -3.5%;
      width: calc(107%);
      height: calc(107%);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-image: url('/assets/img/establishmentpage/header/desktop-header-blue-swoosh.svg');
    }
  }
}
</style>
